import axios from 'axios'
import * as moment from 'moment'
import { getProfilePicV } from './GraphHelpers'

const getProfilePicWithOptimize = async ({
  userStore,
  upn,
  dimensions = '96x96',
  handleError
}: {
  userStore: any
  upn: string
  dimensions?: string
  handleError?: Function
}) => {
  if (!upn) return null
  let profilePic = null

  profilePic = userStore.getProfilePic(upn, dimensions)

  if (!profilePic) {
    try {
      const tokenExpires = moment(userStore.graphTokenExpires).subtract(5, 'minutes')
      if (tokenExpires.isBefore(moment())) {
        const { data } = await axios.get('/api/common/getNewTokens')
        userStore.setNewToken(data.graphAccessToken, data.graphTokenExpires)
      }
      profilePic = await getProfilePicV(userStore.graphAccessToken, upn, dimensions)
      userStore.addPic(upn, profilePic, dimensions)
    } catch (e) {
      handleError && handleError(e)
      return ''
    }
  }
  return profilePic
}

export default getProfilePicWithOptimize
