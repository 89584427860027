import axios from 'axios'
import { PersonaPresence } from 'office-ui-fabric-react'

/*export  function getProfilePic (accessToken,UPN) {
  return 'x'
}*/
const graph = require('@microsoft/microsoft-graph-client')

export async function getProfilePic(accessToken, UPN) {
  return new Promise(async (resolve, reject) => {
    const client = graph.Client.init({
      authProvider: done => {
        done(null, accessToken)
      }
    })
    try {
      let res = await client
        .api(`users/${UPN}/photos/96x96/$value`)
        //.header('Cache-Control', 'no-cache')
        .responseType('blob')
        .get()
      const blobUrl = window.URL.createObjectURL(res)
      resolve(blobUrl)
      /*.get((err, res, rawResponse) => {
        if (err) {
          resolve('');
        }
        else {
          const blobUrl = window.URL.createObjectURL(res);
          resolve(blobUrl);
        }
      });*/
    } catch (error) {
      resolve('')
    }
  })
}

export async function getProfilePicV(accessToken, UPN, dimensions) {
  return new Promise(async (resolve, reject) => {
    const client = graph.Client.init({
      authProvider: done => {
        done(null, accessToken)
      }
    })

    try {
      let res = await client
        .api(`users/${UPN}/photos/${dimensions}/$value`)
        //.header('Cache-Control', 'no-cache')
        .responseType('blob')
        .get()
      const blobUrl = window.URL.createObjectURL(res)
      resolve(blobUrl)
    } catch (error) {
      reject(error)
    }
    /*const config = {
      params: {
        upn:UPN
      }
    }
    let res = null
    //FETCHING

     res = await axios.get('/api/common/getPhoto', config)
     console.log(res)
     const blobUrl = window.URL.createObjectURL(res.data)
     resolve(blobUrl)*/
  })
}

export async function getProfilePicLarge(accessToken, UPN) {
  return new Promise(async (resolve, reject) => {
    try {
      const client = graph.Client.init({
        authProvider: done => {
          done(null, accessToken)
        }
      })
      let res = await client
        .api(`users/${UPN}/photo/$value`)
        //.header('Cache-Control', 'no-cache')
        .responseType('blob')
        .get()
      const blobUrl = window.URL.createObjectURL(res)
      resolve(blobUrl)
      /*client
      .api(`users/${UPN}/photo/$value`)
      //.header('Cache-Control', 'no-cache')
      .responseType('blob')
      .get((err, res, rawResponse) => {
        if (err) {
          console.log('basan')
          resolve('')
        }
        else {
          const blobUrl = window.URL.createObjectURL(res);
          resolve(blobUrl);
        }
      });*/
    } catch (error) {
      console.log('basan ', error)
      resolve('')
    }
  })
}

export async function fetchPresence(userId, accessToken) {
  if (!userId) return PersonaPresence.none
  const client = graph.Client.init({
    authProvider: done => {
      done(null, accessToken)
    }
  })
  let data = await client.api(`/users/${userId}/presence`).get()

  const switchResult = () => {
    switch (data.activity) {
      case 'Offline':
        return PersonaPresence.offline
      case 'Available':
        return PersonaPresence.online
      case 'BeRightBack':
        return PersonaPresence.away
      case 'Away':
        return PersonaPresence.away
      case 'DoNotDisturb':
        return PersonaPresence.dnd
      case 'Blocked':
        return PersonaPresence.blocked
      case 'Busy':
        return PersonaPresence.busy
      default:
        return PersonaPresence.offline
    }
  }
  let a = switchResult()
  return a
}

export async function fetchMyPresence() {
  let { data } = await axios.get('/api/common/getMyPresence')
  const switchResult = () => {
    switch (data.activity) {
      case 'Offline':
        return PersonaPresence.offline
      case 'Available':
        return PersonaPresence.online
      case 'BeRightBack':
        return PersonaPresence.away
      case 'Away':
        return PersonaPresence.away
      case 'DoNotDisturb':
        return PersonaPresence.dnd
      case 'Blocked':
        return PersonaPresence.blocked
      case 'Busy':
        return PersonaPresence.busy
      default:
        return PersonaPresence.offline
    }
  }
  let a = switchResult()
  return a
}

// export async function getPresence(accessToken, users) {
//   return new Promise((resolve, reject) => {
//     const ids = users.map(user => user.id)
//     const presence = {
//       ids
//     }
//     const options = {
//       authProvider: done => {
//         done(null, accessToken)
//       }
//     }
//     const client = graph.Client.init(options)
//     client
//       .api(`/communications/getPresencesByUserId`)
//       .version('beta')
//       .post(presence)
//       .then(response => resolve(response))
//       .catch(err => reject(err))
//   })
// }

export async function getMyPresence(accessToken) {
  return new Promise(async (resolve, reject) => {
    console.log('içerde')
    const options = {
      authProvider: done => {
        done(null, accessToken)
      }
    }

    const client = graph.Client.init(options)

    let presence = client.api('/users/9fd850b8-ad24-4a53-a9e7-8e3b3c1d15ff/presence').get()
    resolve(presence)
  })
}
